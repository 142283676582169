import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { img, imgBrand, alt, isBe } from '../../lib/brand';
import BlogRoll from '../../components/BlogRoll';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import FindLO from '../../components/FindLO';
import Calculators from '../../components/Calculators';
import ProductDetails from '../../components/ProductDetails';
import Accent from '../../components/Accent';
import HTMLContent from '../../components/Content';
import StaticText from '../../components/StaticText';

var hero = img('hero.jpg', 'va-loans');
var icon = imgBrand('typesImg/Icons_VA_active.png');

import styles from './styles.module.less';

const VALoans = ({ staticTextItems }) => {
   const data = useStaticQuery(graphql`
      query VALoansQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["VH Loans"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const header = <Header active={['current-homeowner', 'home-loans', 'va-loans']} />;
   const intro = 'VA Loans';
   const subhead = (
      <div>
         Helping veterans come back
         <br /> to a place that feels like home.
      </div>
   );

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{alt('VA Loans - Home Loans')}</title>
            <meta
               name="description"
               content="VA home loans are for currently serving military members and require no down payment. Want to find out if you qualify? Contact us today."
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <div className={styles.productHero}>
            <div className={styles.HeroImg} style={{ backgroundImage: `url(${hero})` }}>
               <div className={styles.heroCopy}>
                  <div className={styles.iconContainer}>
                     <img src={icon} alt={alt('VA Loan')} />
                  </div>
                  <div className={styles.copy}>
                     <div className={styles.header}>{intro}</div>
                     <Accent />
                     <HTMLContent className={styles.subhead} content={subhead} />
                  </div>
               </div>
            </div>
         </div>
         <ProductDetails title={'What is a VA home loan?'} border={true}>
            <p>
               A VA home loan is a mortgage guaranteed by the United States Department of Veteran Affairs, designed to
               help military members move into the homes of their dreams.
            </p>
         </ProductDetails>
         <ProductDetails title={'What are some VA home loan benefits?'} border={true}>
            <ul>
               <li>No down payment for purchases</li>
               <li>No prepayment penalties</li>
               <li>Lower average interest rates</li>
               <li>No monthly mortgage insurance</li>
               <li>Limitations on buyer’s closing costs</li>
               <li>Cash-out loans</li>
               <li>Interest rate reduction loans</li>
            </ul>
         </ProductDetails>
         <ProductDetails title={'What are the current rates of a VA loan?'} border={true}>
            <StaticText id="va-loans-current-rates" items={staticTextItems} className={styles.staticText} />
         </ProductDetails>
         <ProductDetails title={'How do I qualify for VA home loan eligibility?'}>
            <>
               <p>
                  Qualifying for a VA loan takes more than military service. Although it’s definitely a base
                  requirement, VA home loan requirements say you will need to have served the equivalent of one of the
                  following terms:
               </p>
               <ul>
                  <li>Honorable discharge after 90 days of active service during wartime</li>
                  <li>Honorable discharge after 181 days of active service during peacetime</li>
                  <li>Two-year requirement of service enlisted after 9/7/1980</li>
                  <li>Six or more years of service in the National Guard or Reserves</li>
               </ul>
               <StaticText id="va-loans-qualify" items={staticTextItems} className={styles.staticText} />
            </>
         </ProductDetails>
         {isBe && (
            <>
               <Calculators
                  showCalculatorMortgage={true}
                  showCalculatorRentVsBuy={true}
                  showCalculatorHomeAffordability={true}
                  showCalculatorRequiredIncome={true}
               />
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="vh-loans"
                  />
               )}
            </>
         )}
      </Layout>
   );
};

export default VALoans;
